<template>
  <RevButtonCard
    class="h-full overflow-hidden"
    :class="{ 'outline-default-hi': hasFocus }"
    data-qa="productCard"
    :hoverable="true"
    :to="url"
  >
    <div
      class="flex h-full flex-col pt-16 lg:pt-0"
      data-spec="product-card-content"
    >
      <div
        class="flex grow flex-col items-center gap-8 px-16 md:flex-row md:px-0"
        data-spec="main-content"
      >
        <div
          class="relative mx-auto my-auto flex flex-col items-center gap-2 p-16 pb-0 md:w-1/2"
          data-spec="image+colors"
        >
          <div data-spec="image+pill">
            <div
              v-if="percentageDifference"
              class="bg-static-default-hi text-static-default-hi mood-purple rounded-full absolute -translate-x-8 -translate-y-8 -rotate-6 px-12 py-4 text-center"
            >
              <div class="body-2-bold">
                {{
                  i18n(translations.pillDiscountPercentage, {
                    percent: percentageDifference,
                  })
                }}
              </div>

              <div class="caption-bold -mt-4">
                {{ i18n(translations.pillDiscountBody) }}
              </div>
            </div>
            <RevIllustration
              :alt="imageAlt"
              :height="160"
              :src="imageSrc"
              :width="160"
            />
          </div>

          <ColorSwatches v-if="colorSwatches" :colors="colorSwatches" />
        </div>

        <div
          class="flex w-full grow flex-col gap-6 md:w-auto lg:pr-16"
          data-spec="product-info"
        >
          <div class="flex flex-col gap-2" data-spec="titles">
            <h2 class="body-1-bold line-clamp-2">{{ title }}</h2>
            <h3
              v-if="description"
              class="text-static-default-low body-2 line-clamp-2"
            >
              {{ description }}
            </h3>
            <span v-if="warranty" class="body-2">
              {{ i18n(translations.productCardWarranty, { warranty }) }}
              {{ i18n(translations.months) }}
            </span>
          </div>

          <ProductCardRatings
            v-if="reviewRating?.count && reviewRating?.average"
            :count="reviewRating.count"
            data-spec="rating"
            :score="reviewRating.average"
          />

          <div v-if="specifications && specifications.length > 0">
            <ProductSpecs :specifications="specifications" />
          </div>

          <div data-spec="price-information">
            <div class="text-static-default-low caption">
              {{ i18n(translations.startingFrom) }}
            </div>
            <PriceWithCrossedPrice
              :price="i18n.price(price)"
              :price-new="priceNew && i18n.price(priceNew)"
            />
          </div>

          <div>
            <RevButtonTiny
              class="mt-8 hidden md:block"
              :icon="IconChevronRight"
              :tabindex="-1"
              variant="primary"
            >
              {{ i18n(translations.cta) }}
            </RevButtonTiny>
          </div>

          <RevIllustration
            alt="Illustration"
            class="hidden"
            :height="64"
            src="/img/product-card/cashHandCroppedRight.svg"
            :width="64"
          />
        </div>
      </div>

      <div class="flex items-center gap-16" data-spec="hero-explanation">
        <RevIllustration
          alt="Illustration"
          :height="64"
          src="/img/product-card/cashHandCroppedBottomLeft.svg"
          :width="64"
        />

        <div>
          <div class="text-static-brand-mid body-2-bold">
            {{ i18n(translations.explanationTitle) }}
          </div>

          <div class="text-static-brand-mid body-2">
            {{ i18n(translations.explanationBody) }}
          </div>
        </div>
      </div>
    </div>
  </RevButtonCard>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { MonetaryAmount } from '@backmarket/http-api'
import ColorSwatches from '@backmarket/nuxt-layer-recommendation/ColorSwatches.vue'
import PriceWithCrossedPrice from '@backmarket/nuxt-layer-recommendation/PriceWithCrossedPrice.vue'
import ProductCardRatings from '@backmarket/nuxt-layer-recommendation/ProductCardRatings.vue'
import ProductSpecs from '@backmarket/nuxt-layer-recommendation/ProductSpecs.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonCard } from '@ds/components/ButtonCard'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevIllustration } from '@ds/components/Illustration'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import translations from './HighlightedProductCard.translations'

const props = defineProps<{
  specifications?: Array<{ label: string; values: string[] }>
  description?: string
  warranty?: number
  imageAlt: string
  imageSrc: string
  price: MonetaryAmount
  priceNew?: MonetaryAmount
  reviewRating?: { count: number; average: number }
  title: string
  url: string
  colorSwatches: Array<{ name: string; value?: string }>
}>()

const percentageDifference = computed(() => {
  if (props.priceNew) {
    const price = parseFloat(props.price.amount)
    const priceNew = parseFloat(props.priceNew.amount)

    return Math.ceil((100 * (price - priceNew)) / priceNew)
  }

  return undefined
})

const i18n = useI18n()
const hasFocus = ref(false)
</script>
